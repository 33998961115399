<template>
  <div id="landing">

    <script type="application/ld+json">
      {
                "@context": "http://schema.org",
                "@type": "Corporation",
                "name": "Melo",
                "description": "Le meilleur moteur de recherche immobilier et agrégateur d'annonces immobilières disponible via une API permettant d'accéder à toute la data de l'immobilier.",
                "logo": "https://www.melo.io/assets/socials/fb-profile.png",
                "address": {
                  "@type": "PostalAddress",
                  "addressCountry": "France",
                  "addressLocality": "Paris",
                  "addressRegion": "IDF",
                  "postalCode": "75002",
                  "streetAddress": "9 rue des Colonnes"
                },
                "email": "mailto:contact@melo.io",
                "url": "https://www.melo.io"
              }
    </script>
    <header class="masthead">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-8 mx-auto mt-5">
            <h1>Le meilleur moteur de recherche immobilier</h1>
            <h2 class="my-3 px-md-5">
              Parcourez plus de
              <strong class="border-bottom-blue">900</strong>
              plateformes d'annonces immobilières à la fois depuis un dashboard
              centralisant toutes vos recherches.
            </h2>
            <b-btn to="/register" class="text-uppercase px-4 py-3 my-3 btn-blue">
              Essayer gratuitement
            </b-btn>
            <ul class="features-list justify-content-center d-md-flex my-3">
              <li>Sans engagement</li>
              <li>14 jours d'essai gratuit</li>
              <li>API, Webhooks</li>
            </ul>
          </div>
        </div>
        <div class="row"></div>
        <div class="row justify-content-center text-center">
          <div class="col mx-auto">
            <video autoplay="autoplay" loop="loop" muted="muted">
              <source :src="require('@/assets/video/animation.mp4')" type="video/mp4" />
              Votre navigateur ne peut pas lire la vidéo.
            </video>
          </div>
        </div>

        <div class="row text-center numbers mt-5">
          <div class="col-md-4">
            <h4><strong>50,000 +</strong></h4>
            <p>Nouvelles annonces / jour</p>
          </div>
          <div class="col-md-4">
            <h4><strong>900 +</strong></h4>
            <p>Sites analysés en temps réel</p>
          </div>
          <div class="col-md-4">
            <h4><strong>34,000 +</strong></h4>
            <p>Villes recensées</p>
          </div>
        </div>
      </div>
    </header>

    <section class="container-fluid bg-light">
      <div class="container">
        <div class="row mb-md-5">
          <div class="col mx-auto">
            <h3>
              Les annonces ne sont pas toutes sur les grands portails
              immobiliers
            </h3>
            <h4 class="my-4">Il serait dommage de les rater, non ?</h4>
          </div>
        </div>
        <div class="row mb-2 d-none d-md-flex">
          <div class="col-md-6">
            <div class="p-2 card">
              <h6 class="text-light-blue">
                Survolez le graphique pour voir les données ⤵
              </h6>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-2 card">
              <h6 class="text-red">Cliquez sur une légende pour la masquer</h6>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-6 mb-2 mb-md-0">
            <AdsCategoryBuy v-if="data.adsCategoryBuy" />
            <div v-else class="card h-100">
              <Spinner class="my-auto p-5" :size="40" :line-size="7" line-fg-color="#3d5afe" :speed="0.6"></Spinner>
            </div>
          </div>
          <div class="col-md-6">
            <AdsCategoryRent v-if="data.adsCategoryRent" />
            <div v-else class="card h-100">
              <Spinner class="my-auto p-5" :size="40" :line-size="7" line-fg-color="#3d5afe" :speed="0.6"></Spinner>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mx-auto">
            <h2>
              Melo regroupe à la fois les annonces des
              <strong>portails</strong> mais a vocation à recenser aussi celles
              publiées sur tous les autres <strong>sites</strong> des
              <strong>30 000 agences immobilières</strong> françaises.
            </h2>
            <ul class="features-list justify-content-center d-md-flex my-3">
              <li>Gain de temps</li>
              <li>Réactivité en temps réel</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid powers bg-light-blue mt-0">
      <div class="container">
        <div class="row text-center mb-md-3">
          <div class="col mx-auto">
            <h3>Les pouvoirs que vous donnera Melo</h3>
            <h4 class="my-4">
              Des fonctionnalités pensées avec les professionnels de
              l'immobilier.
            </h4>
          </div>
        </div>
        <div class="row justify-content-center mb-3 mb-md-4 power-list">
          <ul class="d-md-flex pl-0">
            <li class="mr-md-3">
              <b-button :class="{ active: cat == 'apis' }" @click="
                showCat('apis');
              showSlide('api-properties');
              ">
                <IconGlobe />
                APIs
              </b-button>
            </li>
            <li class="mr-md-3">
              <b-button :class="{ active: cat == 'invest' }" @click="
                showCat('invest');
              showSlide('opportunities');
              ">
                <IconFunnelDollar />
                Investissement
              </b-button>
            </li>
            <li class="mr-md-3">
              <b-button :class="{ active: cat == 'hunter' }" @click="
                showCat('hunter');
              showSlide('custom');
              ">
                <IconAstronaut />
                Chasseur
              </b-button>
            </li>
          </ul>
        </div>
        <div class="row align-items-center">
          <div class="col d-flex justify-content-center align-items-center power-col">
            <b-img alt="melo-api-properties" v-show="slide == 'api-properties'" class="power" key="7"
              v-lazy="require('@/assets/img/api-properties.svg')"></b-img>
            <b-img alt="melo-api-opportunities" v-show="slide == 'api-opportunities'" class="power" key="8"
              v-lazy="require('@/assets/img/api-opportunities.svg')"></b-img>
            <b-img alt="melo-api-opportunities" v-show="slide == 'opportunities'" class="power" key="9"
              v-lazy="require('@/assets/img/opportunities-json.png')"></b-img>
            <b-img alt="melo-modules-zapier" v-show="slide == 'zapier'" class="power" key="10"
              v-lazy="require('@/assets/img/zapier.webp')"></b-img>
            <b-img alt="melo-modules-zapier" v-show="slide == 'make'" class="power" key="11"
              v-lazy="require('@/assets/img/make.png')"></b-img>
            <b-img alt="melo-expressions" v-show="slide == 'expressions'" class="power" key="4"
              v-lazy="require('@/assets/img/expressions.svg')"></b-img>
            <b-img alt="melo-conversation" v-show="slide == 'conversation'" class="power" key="2"
              v-lazy="require('@/assets/img/conversation.svg')"></b-img>
            <b-img alt="melo-price" v-show="slide == 'price'" class="power" key="5"
              v-lazy="require('@/assets/img/price.svg')"></b-img>
            <b-img alt="melo-cities" v-show="slide == 'cities'" class="power" key="6"
              v-lazy="require('@/assets/img/locations.svg')"></b-img>
            <b-img alt="melo-cart" v-show="slide == 'cart'" class="power" key="3"
              v-lazy="require('@/assets/img/cart.svg')"></b-img>
            <b-img alt="melo-custom" v-show="slide == 'custom'" class="power" key="1"
              v-lazy="require('@/assets/img/customization.svg')"></b-img>
          </div>
          <div class="col-md-4 d-flex align-items-center justify-content-center power-list">
            <ul class="mb-0 pl-0">
              <li v-show="this.cat == 'invest'">
                <b-button :class="{ active: slide == 'opportunities' }" class="position-relative"
                  @click="showSlide('opportunities')">
                  <IconFunnelDollar />
                  Opportunités
                </b-button>
              </li>
              <li v-show="this.cat == 'apis'">
                <b-button @click="showSlide('api-properties')" :class="{ active: slide == 'api-properties' }">
                  <IconGlobe />
                  API Properties
                </b-button>
              </li>
              <li v-show="this.cat == 'apis'">
                <b-button @click="showSlide('api-opportunities')" :class="{ active: slide == 'api-opportunities' }"
                  class="position-relative">
                  <IconGlobe />
                  API Analytics
                </b-button>
              </li>
              <li v-show="this.cat == 'apis'">
                <b-button @click="showSlide('make')" :class="{ active: slide == 'make' }">
                  <IconMake class="nocode" />
                </b-button>
              </li>
              <li v-show="this.cat == 'apis'">
                <b-button @click="showSlide('zapier')" :class="{ active: slide == 'zapier' }">
                  <IconZapier class="nocode" />
                </b-button>
              </li>
              <li v-show="this.cat == 'hunter'">
                <b-button :class="{ active: slide == 'custom' }" @click="showSlide('custom')">
                  <IconCopyright />
                  Personnalisation
                </b-button>
              </li>
              <li v-show="this.cat == 'hunter'">
                <b-button :class="{ active: slide == 'cart' }" @click="showSlide('cart')">
                  <IconPaperplane />
                  Envois à vos clients
                </b-button>
              </li>
              <li v-show="this.cat == 'hunter'">
                <b-button :class="{ active: slide == 'conversation' }" @click="showSlide('conversation')">
                  <IconComment class="py-1" />
                  Conversation
                </b-button>
              </li>

              <li v-show="this.cat == 'invest' || this.cat == 'hunter'">
                <b-button :class="{ active: slide == 'expressions' }" @click="showSlide('expressions')">
                  <IconText />
                  Expressions
                </b-button>
              </li>
              <li v-show="this.cat == 'invest' || this.cat == 'hunter'">
                <b-button :class="{ active: slide == 'price' }" @click="showSlide('price')">
                  <IconPrice />
                  Prix / m²
                </b-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="container mt-0 pb-0">
      <div class="row text-center">
        <div class="col-md-10 mx-auto">
          <h3>Pour tous les métiers de l'immobilier</h3>
          <h4 class="my-4 px-md-5">
            Rejoignez les chasseurs, agents, banques et assurances qui utilisent
            nos services.
          </h4>
        </div>
      </div>

      <Customers />
    </section>
    <section class="mt-0 pb-5 pt-0">
      <Testimonials />
    </section>
    <Press />
    <Footer />
  </div>
</template>

<script>
import Testimonials from "@/components/content/Testimonials.vue";
import Customers from "@/components/content/Customers.vue";
import IconPrice from "@/components/icons/IconPrice.vue";
import IconFunnelDollar from "@/components/icons/IconFunnelDollar.vue";
import IconLocations from "@/components/icons/IconLocations.vue";
import IconPaperplane from "@/components/icons/IconPaperplane.vue";
import IconText from "@/components/icons/IconText.vue";
import IconData from "@/components/icons/IconData.vue";
import IconCopyright from "@/components/icons/IconCopyright.vue";
import IconSubmit from "@/components/icons/IconSubmit.vue";
import IconComment from "@/components/icons/IconComment.vue";
import IconGlobe from "@/components/icons/IconGlobe.vue";
import IconZapier from "@/components/icons/IconZapier.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import IconNinja from "@/components/icons/IconNinja.vue";
import IconMake from "@/components/icons/IconMake.vue";
import IconAstronaut from "@/components/icons/IconAstronaut.vue";
import Footer from "@/components/bottombar/Footer.vue";
import Press from "@/components/bottombar/Press.vue";
import AdsCategoryBuy from "@/components/data/AdsCategoryBuy.vue";
import AdsCategoryRent from "@/components/data/AdsCategoryRent.vue";
import Spinner from "vue-simple-spinner";
import Chart from "chart.js";
import Vue from "vue";
Chart.defaults.global.defaultFontColor = "#bababa";
Chart.defaults.global.defaultFontFamily = "Moderat";
import { mapGetters, mapState } from "vuex";

export default {
  metaInfo: {
    title: "Le meilleur moteur de recherche immobilier",
    titleTemplate: "%s — Melo",
    meta: [
      { "http-equiv": "Content-Type", content: "text/html; charset=utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Le meilleur moteur de recherche immobilier et agrégateur d'annonces immobilières disponible via une API permettant d'accéder à toute la data de l'immobilier.",
      },
      {
        property: "og:title",
        content: "Le meilleur moteur de recherche immobilier — Melo",
      },
      { property: "og:site_name", content: "Melo" },
      { property: "og:type", content: "website" },
      {
        property: "og:url",
        content: "https://www.melo.io",
      },
      {
        property: "og:image",
        content: "https://www.melo.io/assets/socials/fb-cover.png",
      },
      {
        property: "og:description",
        content:
          "Le meilleur moteur de recherche immobilier et agrégateur d'annonces immobilières disponible via une API permettant d'accéder à toute la data de l'immobilier.",
      },
    ],
    link: [{ rel: "canonical", href: "https://www.melo.io" }],
  },
  components: {
    IconPrice,
    IconLocations,
    IconPaperplane,
    IconText,
    IconData,
    IconSearch,
    IconCopyright,
    IconSubmit,
    IconComment,
    IconMake,
    IconZapier,
    Footer,
    Customers,
    AdsCategoryBuy,
    AdsCategoryRent,
    Spinner,
    IconGlobe,
    IconAstronaut,
    IconNinja,
    IconFunnelDollar,
    Testimonials,
    Press
  },
  data() {
    return {
      slide: "api-properties",
      cat: "apis",
    };
  },
  computed: {
    ...mapState(["data", "userData"]),
    ...mapGetters(["isLoggedIn"])
  },
  methods: {
    showSlide(value) {
      this.slide = value;
    },
    showCat(value) {
      this.cat = value;
    },
  },
  created() {
    this.$store.dispatch("getDataFromES", {
      target: "adsCategoryBuy",
      transactionType: 0,
    });
    this.$store.dispatch("getDataFromES", {
      target: "adsCategoryRent",
      transactionType: 1,
    });
  },
  mounted() {
    this.$nextTick(() => {
      // Handle user identification
      if (this.isLoggedIn && window.$chatwoot && this.userData && this.userData.email) {
        try {
          window.$chatwoot.setUser(btoa(this.userData.email), {
            email: this.userData.email,
            name: this.userData.email.split("@")[0],
          });
        } catch (error) {
          console.error('Chatwoot setUser error:', error);
        }
      }

      // Wait for Chatwoot to be fully loaded before toggling visibility
      const checkChatwootReady = setInterval(() => {
        const chatwootWidget = document.querySelector('#chatwoot_live_chat_widget');
        if (window.$chatwoot && chatwootWidget) {
          try {
            window.$chatwoot.toggleBubbleVisibility("show");
            clearInterval(checkChatwootReady);
          } catch (error) {
            console.error('Chatwoot toggle visibility error:', error);
          }
        }
      }, 1000);

      // Clear interval after 10 seconds to prevent infinite checking
      setTimeout(() => {
        clearInterval(checkChatwootReady);
      }, 10000);
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/cards.scss";

* {
  letter-spacing: 1px;
}

video {
  width: 100%;
}

h1 {
  font-weight: 700;
  letter-spacing: -0.06em;
  line-height: 1;

  @include media-breakpoint-up(md) {
    font-size: 4.2rem;
  }
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-weight: bold;

  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }
}

p {
  font-weight: normal;
}

.numbers {
  h4 {
    color: $blue;
    font-size: 2.5rem;
  }

  p {
    font-size: 1.2rem;
  }
}

.features-list {
  font-size: 1.2rem;
  display: flex;
  list-style: none;
  flex-wrap: wrap;

  li {
    display: flex;
    align-items: center;
    margin-right: 40px;

    @include media-breakpoint-up(md) {
      margin-right: 40px;
    }
  }
}

section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-top: 5rem;
}

.border-line {
  margin-bottom: 1rem;
  padding-right: 1rem;
  border-bottom: 2px solid rgb(0, 0, 0) !important;
}

/* .power-col {
  height: 20rem;
  @include media-breakpoint-up(md) {
    height: auto;
  }
} */

.power {
  box-shadow: $box-shadow;
  border-radius: $radius;
  width: 100%;
  height: auto;
  display: block;
  margin: 0;
}

.nocode {
  width: 5rem !important;
  height: 2rem;
}

.power-list {
  margin-top: 2rem;

  @include media-breakpoint-up(md) {
    margin-top: unset;
  }

  li {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }
  }

  .btn {
    width: 100%;
    padding: 1rem;
    background: white;
    color: unset;
    text-align: left;

    svg {
      margin-right: 0.5rem;
    }
  }

  .btn:hover,
  .btn.active {
    background: $blue;
    color: white;
  }
}

.mx-md-10 {
  @include media-breakpoint-up(md) {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}

.features {
  h4 {
    font-weight: bold;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;

    &:before {
      content: "\A";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $blue;
      display: inline-block;
      margin: 3px 10px;
    }
  }

  h5 {
    font-size: 1.1rem;
    line-height: 1.5;
  }

  h6 {
    line-height: 1.5;
  }
}
</style>
<style lang="scss">
#landing {
  ul.features-list {
    li {
      font-weight: 400;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      &:before {
        margin-right: 0.5rem;
        width: 1.2rem;
        font-size: 1.2rem;
        padding: 0 4px 0 0;
        content: url('data:image/svg+xml; utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="black" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>');
      }

      align-items: center;

      svg {
        width: 1.3rem;
        margin-right: 0.5rem;

        path {
          fill: $blue;
        }
      }
    }
  }
}
</style>
