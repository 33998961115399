<template>
    <section class="testimonials mt-0 pb-5 pt-0">
      <div class="container">
        <div class="row text-center mb-5">
          <div class="col-md-8 mx-auto">
            <h3>Ce qu'ils en pensent</h3>
          </div>
        </div>
        
        <div class="row text-left">
          <div class="col-md-4 mb-4" v-for="(testimonial, index) in testimonials" :key="index">
            <div class="testimonial-card h-100 border-radius p-4">
              <div class="testimonial-content">
                <div class="d-flex align-items-center mb-3">
                  <div class="author-avatar mr-3">
                    <img
                      v-if="testimonial.avatar"
                      :src="testimonial.avatar"
                      :alt="testimonial.name"
                      class="rounded-circle"
                    />
                    <div v-else class="avatar-placeholder rounded-circle bg-dark text-white d-flex align-items-center justify-content-center">
                      {{ testimonial.initials }}
                    </div>
                  </div>
                  <div class="author-info">
                    <h6 class="mb-0 font-weight-bold">{{ testimonial.name }}</h6>
                    <p class="mb-0 text-muted">{{ testimonial.title }}</p>
                  </div>
                </div>
                <div class="testimonial-text mb-3">
                  <p class="mb-0">{{ testimonial.comment }}</p>
                </div>               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: "Testimonials",
   
    data() {
      return {
        testimonials: [
        {
            name: "Thibaud F.",
            title: "@thibaudfrog",
            comment: "Après 6 ans dans l'immobilier, je peux dire que Melo change vraiment la donne ! Plus besoin de jongler entre 15 onglets, tout est centralisé. Le gain de temps est juste dingue. Merci la team !",
            avatar: null,
            initials: "TF"
          },
          {
            name: "Léana C.",
            title: "@leana.realestate",
            comment: "L'immobilier m'a appris une chose : l'efficacité est la clé. Melo simplifie tellement mon quotidien que je me demande comment je faisais avant ! Fini le casse-tête des multiples plateformes, tout est enfin au même endroit. Un vrai gain de temps et d'énergie.",
            avatar: null,
            initials: "LC"
          },
          {
            name: "Vincent R.",
            title: "@vrochetaux",
            comment: "1er mois avec Melo : 3 ventes conclues vs 1 habituellement Le matching automatique avec nos clients est fait gagner un temps fou. Plus jamais je ne retourne aux méthodes classiques !",
            avatar: null,
            initials: "VR"
          },
          {
            name: "Anne Claire G.",
            title: "@ancgui",
            comment: "Les notifs en temps réel prenant toutes les sources en compte quand un bien correspond aux critères = gain de temps total ! J'ai pu proposer un bien à mes clients 1h après sa mise en ligne, offre acceptée dans la foulée",
            avatar: null,
            initials: "ACG"
          },
          {
            name: "Gaelle F.",
            title: "@gaellefaraud",
            comment: "Je devais gérer une vingtaine de biens en parallèle avec des dizaines de visites par semaine. Sans Melo, c'était la pagaille ! Maintenant en quelques clics je retrouve tous mes documents, je peux suivre mes leads et même automatiser mes relances. Mon équipe me charrie parce que je ne jure plus que par Melo, mais franchement, quand on trouve LA solution qui nous change la vie, on a envie de le crier sur tous les toits !",
            avatar: null,
            initials: "GF"
          },
          {
            name: "Marie-Claire B.",
            title: "@mcb_immobilier",
            comment: "Je viens de conclure ma plus rapide transaction grâce aux alertes instantanées de Melo ! Un bien est apparu qui correspondait parfaitement aux critères de mes clients - en 24h tout était bouclé. Dans ce marché compétitif, cette réactivité fait toute la différence. Mes clients étaient ravis, et moi aussi ! ⭐️",
            avatar: null,
            initials: "MB"
          }
        
        ]
      };
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .testimonials {
    .testimonial-card {
      background: white;
      transition: all 0.2s ease-in-out;
      border: 1px solid rgba(0, 0, 0, 0.1);
      
      &:hover {
        transform: translateY(-5px);
      }
    }


    h3 {
      font-weight: bold;
      @include media-breakpoint-up(md) {
        font-size: 3rem;
      }
    }
  
    h4 {
      line-height: 1.5;
    }
  
    .author-avatar {
      img, .avatar-placeholder {
        width: 48px;
        height: 48px;
      }
  
      .avatar-placeholder {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  
    .author-info {
      h6 {
        font-size: 1rem;
      }
  
      p {
        font-size: 0.9rem;
      }
    }
  
    .testimonial-text {
      p {
        font-size: 1rem;
        line-height: 1.5;
      }
    }
  
    .testimonial-meta {
      span {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
      }
  
      .icon {
        width: 1.2rem;
        height: 1.2rem;
        fill: currentColor;
      }
    }
  }
  </style>